<template>
  <div class="content-search-oc_detail">
    <div class="content-text-title">
      <v-icon class="global-icon-arrow-back" @click="back()"
        >mdi-arrow-left</v-icon
      >
      <p class="text-title">
        Detalle de orden de compra {{ sStatusOCMethods() }} :
        {{ sNameDetail }}
      </p>
    </div>
    <v-spacer></v-spacer>
    <div class="content-btn-payable" v-if="$route.name == 'OPPayableDetail'">
      <v-btn @click="paidOC()" class="global-btn-primary"> Marcar como pagada</v-btn>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    sTypeItem: Number,
    sTextTitle: String,
  },
  data() {
    return {
      sStatusOC: "",
    };
  },
  methods: {
    back() {
      switch (this.$route.name) {
        case "OPGeneralDetail":
          this.$router
            .push({
              name: "OPGeneral",
            })
            .catch(() => {});
          break;
        case "OPPendingDetail":
          this.$router
            .push({
              name: "OPPending",
            })
            .catch(() => {});
          break;
        case 3:
          break;

        default:
          break;
      }
    },
    sStatusOCMethods() {
      switch (this.$route.name) {
        case "OPGeneralDetail":
          return "general";

        case "OPPendingDetail":
          return "pendiente";

        case 3:
          return "por pagar";

        default:
          break;
      }
    },
    paidOC() {
      // this.$store.commit("setDialogConfirmationOfPaid", {
      //   active: true,
      // });
    },
  },
  computed: {
    sNameDetail() {
      return this.$store.state.sNameDetail;
    },
  },
};
</script>
<style scoped>
.content-search-oc_detail {
  display: flex;
  align-content: center;
  width: 100%;
}
.content-text-title {
  display: flex;
  width: 80%;
}
.text-title {
  place-self: center;
  color: var(--primary-color-text);
  font-family: "pop-SemiBold";
  font-size: 2rem;
  margin-bottom: 0px;
  margin-right: 5px;
}
.content-icon-currency {
  align-self: center;
  align-items: center;
}
.text-icon-currency {
  color: var(--primary-color-text-black);
}
.content-account-balance {
  padding: 15px;
  display: flex;
  height: 70px;
  width: 280px;
  max-width: 280px;
  min-width: 280px;
  border: 1px solid var(--primary-color-border-input);
  background-color: var(--primary-background-color-form-login);
  border-radius: 10px;
}
.content-text-currency {
  width: 100%;
  align-self: center;
  margin-left: 10px;
}
.text-title-account-balance {
  margin-bottom: 0px;
  font-family: "pop-Regular";
  font-size: 13px;

  color: var(--primary-color-text);
}
.text-value-account-balance {
  font-size: 20px;
  font-family: "pop-semiBold";
  margin-bottom: 0px;
}
.class-in {
  color: var(--primary-color-text-in) !important;
}

.class-out {
  color: var(--primary-color-text-out) !important;
}
.content-btn-payable {
  width: 130px;
}
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
}
/* SM */
@media (min-width: 600px) and (max-width: 959px) {
}
/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}
/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}
/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>